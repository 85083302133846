<template>
  <div ref="opt_comm" class="opt_comm" @click.prevent="openToggle">
    <!-- 비활성일 때 disabled 클래스 추가 / 100px 너비 타입은 type_short 클래스 추가 -->
    <strong class="screen_out">{{ optionDesc }}</strong
    ><!-- 내용에 따라 다르게 입력 -->
    <span class="screen_out">선택내용 : </span>
    <a ref="aria" href="javascript:void(0);" class="link_opt" aria-expanded="false"
      ><!-- 오픈시 true, 닫혔을 때 false -->
      {{ optionName }} <span class="ico_account ico_opt" />
    </a>
    <div class="box_opt">
      <!-- 2019-09-02 추가 시작 -->
      <span class="screen_out">선택옵션 검색</span>
      <div class="info_search">
        <input
          id="defaultSearch0"
          ref="searchInput"
          v-model="searchKeyword"
          type="text"
          class="inp_comm inp_search"
          title="검색하기"
          @focus="initSearchKeyword"
          @keyup="searchCost"
        />
        <span class="ico_account ico_search" />
      </div>
      <!-- // 2019-09-02 추가 끝 -->
      <span class="screen_out">선택옵션 목록</span
      ><!-- 2019-09-02 수정 -->
      <ul class="list_opt" role="listbox">
        <li
          v-for="(item, index) in searchList"
          :key="index"
          :class="'item_depth' + item.deptDepth"
          @click.prevent="selectOption(item)"
        >
          <a
            ref="listIndex"
            href="javascript:void(0);"
            class="link_option"
            role="option"
            :aria-selected="[item.deptName === optionName ? 'true' : 'false']"
            @keyup.down="downFocus(1)"
            @keyup.up="upFocus(1)"
            >{{ item.deptName }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
export default {
  name: "CommOptDeptSearch",
  mixins: [CommLayerMixin],
  props: {
    classList: Array,
    optionName: String,
    optionDesc: String,
    eventName: String,
    url: String,
  },
  data() {
    return {
      option: {},
      selectedOption: {},
      displayOptionName: this.optionName,
      resultList: [],
      optionList: [
        {
          deptName: "전체",
          deptCode: "",
        },
      ],
      searchKeyword: "",
      searchList: [
        {
          deptName: "전체",
          deptCode: "",
        },
      ],
      indexFocus: 0,
    };
  },
  watch: {
    optionName(optionName) {
      this.displayOptionName = optionName;
    },
  },
  created() {
    axios
      .post(this.url)
      .then((res) => {
        res.data.forEach((data) => {
          this.optionList.push(data);
        });
        this.searchList = this.optionList;
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
  methods: {
    openToggle(event) {
      if (this.$refs.aria.getAttribute("aria-expanded") === "true") {
        if (event.target.className.indexOf("inp_search") > 1) {
          return false;
        }
        this.$refs.aria.setAttribute("aria-expanded", "false");
        this.$refs.opt_comm.classList.remove("opt_open");
      } else {
        let optOpenArr = document.querySelectorAll(".opt_open");
        for (let i = 0, leng = optOpenArr.length; i < leng; i++) {
          optOpenArr[i].classList.remove("opt_open");
          optOpenArr[i].querySelector(".link_opt").setAttribute("aria-expanded", "false");
        }
        this.$refs.aria.setAttribute("aria-expanded", "true");
        this.$refs.opt_comm.classList.add("opt_open");
        this.initSearchKeyword();
      }
    },
    searchCost(key) {
      const obj = this;
      let keyCode = key.which;
      if (keyCode === 40) {
        this.indexFocus = 0;
        this.$refs.listIndex[0].focus();
      } else {
        this.searchList = this.optionList.filter(function (st) {
          return st.deptName.includes(obj.searchKeyword);
        });
      }
    },
    selectOption(option) {
      if (this.validate) {
        if (this.validate(option)) {
          this.option = option;
          this.optionName = this.option.deptName;
          this.$emit(this.eventName, option);
        }
      } else {
        this.option = option;
        this.optionName = this.option.deptName;
        this.$emit(this.eventName, option);
      }
      this.$refs.opt_comm.classList.remove("error");
    },
    downFocus(e) {
      const targetList = this.$refs.listIndex;
      if (e === 0) {
        if (targetList.length > 0) {
          targetList[0].focus();
          this.indexFocus = 0;
        }
      } else {
        this.indexFocus = this.indexFocus + 1;
        if (targetList.length > this.indexFocus) {
          targetList[this.indexFocus].focus();
        } else {
          this.indexFocus = 0;
          targetList[0].focus();
        }
      }
    },
    upFocus(e) {
      const targetList = this.$refs.listIndex;
      if (this.indexFocus === 0) {
        this.indexFocus = targetList.length;
        targetList[targetList.length - 1].focus();
      } else {
        this.indexFocus = this.indexFocus - 1;
        targetList[this.indexFocus].focus();
      }
    },
    initSearchKeyword() {
      this.searchKeyword = "";
      this.searchList = this.optionList;
    },
  },
};
</script>
